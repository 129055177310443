// Intro

.intro
  width: 100%
  overflow: hidden
  background: #fff url('../../assets/img/bgs/intro.png') center center no-repeat
  background-size: cover
  +v-xs
    padding-top: 20vh
  &__title
    width: 100%
    color: $premium
    font-size: 3.45em
    font-family: $basic
    font-weight: 900
    line-height: 1
    letter-spacing: .015em
    text-align: center
    transform: translateX(10%)
    background-color: #00000000
    +v-md
      font-size: 4em
    +v-sm
      margin-top: 20px
      font-size: 2.5em
      line-height: 1.05
    +v-xs
      width: 80%
      margin: 20px auto
      font-size: 1.95em
      line-height: 1.25
  &__subtitle
    width: 100%
    padding: 0
    color: $premium
    font-size: 1.35em
    font-weight: 600
    transform: translateX(10%)
    +v-sm
      font-size: 1.2em
      line-height: 1.25
    +v-xs
      width: 80%
      margin: 20px auto
      line-height: 1.5
  &__text
    width: 100%
    margin: 20px auto
    padding: 0
    color: $medium
    font-size: 1.15em
    font-weight: 500
    line-height: 1.5
    +v-sm
      width: 76%
      font-size: .9em
      margin: 10px auto
//   &__smile
//     display: inline-block
//     position: relative
//     top: 2px
//     left: 4px
//     font-style: normal
//     animation: Smile infinite 2s
//     animation: Smile infinite 2s

// @keyframes Smile
//   from
//     transform: rotate(-360deg)
//   to
//     transform: rotate(0)
