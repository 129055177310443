// About
#info_block
  width: 100%
  background: #f1d4c6 url('../../assets/img/bgs/info.jpg')
  background-size: cover
  background-attachment: fixed

  +v-sm
    min-height: auto
    padding: 40px 0
